import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import { TypoSBold, TypoXSBold, TypoXXS, TypoXXXSBold } from '@atoms/Typos';
import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import { PropertyMinCoin } from '@services/hafh/types/generated';
import IconCoin from '@svg-icons/IconCoin';
import { VIP_DISCOUNT_RATE } from '@utils/constants';
import styles from './css';

type Props = {
  minCoin: PropertyMinCoin;
  nights?: number | null;
  noOfGuest?: number;
};

const CoinInfoWithGuestsAndNights = ({
  minCoin,
  nights,
  noOfGuest,
}: Props): JSX.Element => {
  const { loadingAuthUser } = useAppSelector((state) => ({
    loadingAuthUser: state.loadingAuthUser,
  }));
  const { vipRank } = useNeighbor();

  const isCoinFetched = !!minCoin;
  const { t } = useTranslation('property');
  const guestsText =
    (noOfGuest &&
      `${t('planCalendar.guestNumberUnit', {
        guestNumber: noOfGuest,
      })} / `) ||
    '';

  const nightsText = t('stayUnit', {
    stay: nights || 1,
  });

  if (loadingAuthUser || !isCoinFetched) {
    return <Image alt="loading" height={8} src="/loading.gif" width={30} />;
  }

  if (!minCoin?.regular) {
    return (
      <div className="property-no-rooms">
        <TypoXSBold text={t('noRooms')} />
      </div>
    );
  }

  return (
    <div className="coin-info-nights-and-guests">
      {(noOfGuest || nights) && (
        <div className="nights-and-guests">
          <TypoXXS color="black600" text={`${guestsText}${nightsText}`} />
        </div>
      )}
      <div className="coin-info-wrapper">
        <div className="coin-info-discount">
          <IconCoin size="sm" withFill={true} />
          <TypoSBold text={`${minCoin?.vip ?? minCoin?.regular}~`} />
        </div>
        {!!minCoin?.vip && !!vipRank && (
          <>
            <div className="coin-info-content">
              <IconCoin color="black600" size="xxs" withFill={false} />
              {minCoin?.regular}~
            </div>
            <div className="vip-badge">
              <TypoXXXSBold
                color="white"
                text={`${VIP_DISCOUNT_RATE[vipRank]}%OFF`}
              />
            </div>
          </>
        )}
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default CoinInfoWithGuestsAndNights;
